import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MDTypography from 'components/MDTypography';
import Typography from '@mui/material/Typography';
import MDAvatar from 'components/MDAvatar';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemButton from '@mui/material/ListItemButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MDBox from 'components/MDBox';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import MDButton from 'components/MDButton';
import Accordion from '@mui/material/Accordion';
import Card from '@mui/material/Card';
import { Tooltip, tooltipClasses } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import ListItemText from '@mui/material/ListItemText';
import colors from 'assets/theme/base/colors';

export const GridContainer = styled(Grid)`
  padding: 10px 30px;
  flex-direction: column;
`;

export const CalendarEventButtonGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

export const CalendarEventButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
`;

export const CalendarEventDeleteButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  border: 1px solid rgba(244, 67, 53, 0.5);
  width: 100%;
  color: #ff0000;
  font-weight: 400;
`;

export const DialogHeader = styled(Box)`
  display: flex;
  z-index: 9;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  top: 0px;
  position: sticky;
  background-color: #ffffff;
`;

export const CategoryList = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CategoryColor = styled(Box)`
  ${(props) => `
     background-color: ${props.color};
     height: 18px;
     width: 18px;
     border-radius: 9px;
     margin-right: 20px;
`};
`;

export const CategoryText = styled(MDTypography)`
  font-weight: 500;
`;

export const ButtonWrapper = styled(Grid)`
  justify-content: end;
`;

export const DialogBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`;

export const UsersGrid = styled(Grid)`
  padding: 0px 30px;
`;

export const TypographyUser = styled(Typography)`
  display: flex;
  align-items: center;
`;

export const ControlForm = styled(FormControl)`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;

export const HelperTextForm = styled(FormHelperText)`
  color: #ff4d4f;
`;

export const UserWrapperBox = styled(MDBox)`
  &.MuiBox-root {
    padding: ${(props) =>
      `${props.theme.palette.spacing[1] * 2}px ${
        props.theme.palette.spacing[1] * 3
      }px`};
  }
`;

export const GridForm = styled(Grid)`
  padding: 0px 40px;
`;

export const FieldTextLabel = styled(TextField)`
  .MuiInputLabel-root {
    padding: 2px;
  }
`;

export const CategoryDialog = styled(Dialog)`
  &.MuiPaper-root {
    width: 800px;
  }
`;

export const AccountsRoundedIconManage = styled(ManageAccountsRoundedIcon)`
  cursor: pointer;
`;

export const Loader = styled(Box)`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonIcon = styled(IconButton)`
  .MuiSvgIcon-root {
    width: 18px;
  }
  cursor: pointer;
`;

export const SmallIconButton = styled(ButtonIcon)`
  padding: 0px;
`;

export const DeleteIconStyle = styled(DeleteIcon)`
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 15px;
`;

export const CalendarLoader = styled(Box)`
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChildSubMenu = styled(Box)`
  padding: 0px 0px 0px 10px;
`;

export const DialogLoader = styled(Box)`
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DataGridSubHeading = styled(Grid)`
  justify-content: end;
`;

export const DataGridPaper = styled(Paper)`
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding: 8px;
`;

export const ButtonSpinner = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ButtonSpinnerProgress = styled(CircularProgress)`
  color: #1976d2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

export const DropdownLabelText = styled(InputLabel)`
  &.MuiInputLabel-root {
    ${(props) => (props.primary ? `color: #F44335` : '')};
  }
`;

export const DatePickeDesktop = styled(DesktopDatePicker)`
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
  border-bottom: ${(props) =>
    props.primary ? '1px solid #F44335' : '1px solid lightgrey'};
  width: -webkit-fill-available;

  .MuiInputLabel-root {
    margin-left: ${(props) => `-${props.theme.palette.spacing[0] * 3.5}px`};
    ${(props) => (props.primary ? `color: #F44335` : '')};
  }
  .MuiOutlinedInput-root fieldset {
    border: none;
  }
  .MuiInputBase-input {
    padding-left: ${(props) => `-${props.theme.palette.spacing[0] * 0}px`};
  }
`;

export const HeadingBox = styled(Box)`
  padding: 8px 0px;
  display: flex;
  justify-content: flex-end;
`;

export const AnnouncementsWrapper = styled(MDBox)`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  line-height: 1;
`;

export const TypographyText = styled(MDTypography)`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
`;

export const TypographyLink = styled(MDTypography)`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #0000ff;
  text-decoration: underline;
`;

export const AccordionBox = styled(MDBox)`
  padding: 0px 16px;
  margin-bottom: 16px;
`;

export const AvatarMD = styled(MDAvatar)`
  margin-right: 16px;
`;

export const StudentHeaderGrid = styled(Grid)`
  align-items: center;
  justify-content: space-between;
`;
export const StudentHeaderLeftGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const AvatarEditButton = styled(MDButton)`
  border-radius: 12px;
`;

export const AccordionWrapper = styled(Accordion)`
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
`;

export const DialogStyleBox = styled(Box)`
  padding: 0px 24px;
`;

export const RoundedIconStarBorder = styled(StarBorderRoundedIcon)`
  &.MuiSvgIcon-root {
    width: 30px;
    height: 30px;
    border: unset;
    color: #25383c;
  }
`;

export const RoundedIconStarRate = styled(StarRateRoundedIcon)`
  &.MuiSvgIcon-root {
    width: 30px;
    height: 30px;
    border: unset;
    color: #25383c;
  }
  &&.MuiSvgIcon-root {
    background-image: inherit;
  }
`;

export const AnnouncementCard = styled(Card)`
  cursor: ${(props) => (!props.ispermission ? `unset` : `pointer`)};
  max-height: 20vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
  }
`;

export const HeadingMuiTypography = styled(Typography)`
  padding: 16px 16px 0px 16px;
`;

export const DashboardLeftGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: ${(props) => (!props.ispermission ? `unset` : `pointer`)};
`;

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    border: '2px solid',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const EventDetailsGrid = styled(Grid)`
  padding: 8px;
  flex-direction: column;
`;

export const OutlinedIconCheckCircle = styled(CheckCircleOutlinedIcon)`
  &.MuiSvgIcon-root {
    width: 30px;
    height: 30px;
    border: unset;
    color: #25383c;
  }
  &&.MuiSvgIcon-root {
    background-image: inherit;
  }
`;

export const OutlinedIconCancel = styled(CancelOutlinedIcon)`
  &.MuiSvgIcon-root {
    width: 30px;
    height: 30px;
    border: unset;
    color: #25383c;
  }
`;

export const DashboardDialogHeader = styled(Box)`
  display: flex;
  z-index: 9;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  top: 0px;
  position: sticky;
  background-color: #42424a;
  border-radius: 10px 10px 0px 0px;
`;

export const DashboardCard = styled(Card)`
  position: sticky;
  height: 32vh;
  max-height: 32vh;
  overflow-y: scroll;
  border-radius: 0px 0px 10px 10px;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
  }
`;

export const TypographyHeading = styled(Typography)`
  color: #ffffff;
`;

export const CustomListbox = forwardRef((props, ref) => {
  const StyledListbox = styled('ul')`
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 9px;
    }
    ::-webkit-scrollbar-thumb {
      background: #dcdcdc;
      border-radius: 10px;
    }
  `;

  return <StyledListbox {...props} ref={ref} />;
});

export const AssetAccordionBox = styled(MDBox)`
  margin-bottom: 8px;
`;

export const AccordianContainer = styled(Accordion)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  .MuiAccordionDetails-root {
    min-height: 0;
    max-height: 38vh;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 9px;
    }
    ::-webkit-scrollbar-thumb {
      background: #dcdcdc;
      border-radius: 10px;
    }
  }
`;

export const MassBox = styled(MDBox)`
  padding: 16px;
`;

export const MassGrid = styled(Grid)`
  padding: 0px 16px;
`;

export const DropDownBox = styled(Box)`
  width: 25%;
`;

export const BoxWrapper = styled(Box)`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
  }
`;

export const CircularProgressWithLabelContainer = styled(Box)`
  position: relative;
  display: inline-flex;
`;

export const CircularProgressWithLabelWrapper = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownInputListBox = styled(Box)`
  margin-bottom: 2px;
`;

export const GridCenter = styled(Grid)`
  justify-content: center;
`;

export const BoxCenter = styled(Box)`
  padding: 16px 16px 0px 16px;
`;

export const GridButtonWrapper = styled(Grid)`
  margin-top: 30px;
  padding: 0px 40px;
`;

const IconWithCursor = (IconComponent) => styled(IconComponent)`
  cursor: pointer;
`;

export const DeleteActionIcon = IconWithCursor(DeleteIcon);
export const EditActionIcon = IconWithCursor(EditIcon);
export const RoundedIconVisibility = IconWithCursor(VisibilityRoundedIcon);
export const RoundedIconArchive = IconWithCursor(ArchiveRoundedIcon);
export const RoundedIconUnarchive = IconWithCursor(UnarchiveRoundedIcon);
export const RoundedIconGroups = IconWithCursor(GroupsRoundedIcon);
export const IconArticle = IconWithCursor(ArticleIcon);
export const RoundedIconCloudUpload = IconWithCursor(CloudUploadRoundedIcon);
export const OutlinedIconClose = IconWithCursor(CloseOutlinedIcon);
export const RoundedIconCloudDownload = IconWithCursor(
  CloudDownloadRoundedIcon
);

export const SkeletonLoader = styled(Skeleton)`
  width: 230px;
  height: 80%;
  margin-left: 10px;
`;

export const UploadBox = styled(Box)`
  background-color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-top: 8px;
  position: relative;
`;

export const UploadText = styled(Typography)`
  color: #ffffff;
  font-weight: 500;
  padding: 0px 10px;
`;

export const RoundedCloudUpload = styled(CloudUploadRoundedIcon)`
  color: #ffffff;
`;

export const RoundedIconFolder = styled(FolderRoundedIcon)`
  font-size: 60px !important;
  cursor: pointer;
  color: #f8d775;
`;

export const DocumentDownloadIcon = styled(SimCardDownloadIcon)`
  font-size: 60px !important;
  cursor: pointer;
  color: #696969;
`;

export const DocumentGrid = styled(Grid)`
  margin-left: 16px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const FullCard = styled(Card)`
  height: 80vh;
  overflow: hidden;
`;

export const SubFolderHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
  position: sticky;
  background-color: #ffffff;
  padding: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  height: ${(props) => `${props.theme.palette.spacing[1] * 6}px`};
`;

export const SubfoldersFilesGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 2
    }px`};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
  }
`;

export const NoSubfoldersFilesGrid = styled(Grid)`
  height: inherit;
  align-items: center;
  justify-content: center;
  padding: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const WhiteTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow={false} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const GridWrapper = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 2
    }px`};
`;

export const DashboardCalendarGrid = styled(Grid)`
  cursor: pointer;
  margin-top: ${(props) => `-${props.theme.palette.spacing[1] * 2}px`};
`;

export const TextTypography = styled(MDTypography)`
  overflow: hidden;
  max-height: 48px;
`;

export const CalendarAccessErrorGrid = styled(Grid)`
  height: 50vh;
  justify-content: center;
  align-items: center;
`;

export const FilesIconBox = styled(Box)`
  background: ${(props) => {
    const positions = {
      'image-icon': '-107px -405px',
      'video-icon': '-107px -270px',
      'text-icon': '-361px -136px',
      'msword-icon': '-488px -136px',
      'pdf-icon': '-233px -135px',
      'excel-icon': '-615px -136px',
      'gif-icon': '-361px -405px',
      'music-icon': '-615px -404px',
    };
    return `url(${props.url}) ${positions[props.fileextension] || 'initial'}`;
  }};
  width: 60px;
  height: 70px;
  background-size: 1056px;
`;

export const TimeOutGrid = styled(Grid)`
  padding: 8px 32px;
`;

export const GridBaselineWrapper = styled(GridWrapper)`
  align-items: baseline;
`;

export const StudentProfileNoteGrid = styled(Grid)`
  position: relative;
  right: ${(props) => `${props.theme.palette.spacing[1] * 6}px`};
`;

export const PButton = styled(Button)`
  padding: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
`;

export const ButtonGrid = styled(Grid)`
  display: flex;
  justify-content: end;
`;

export const NoInfoGrid = styled(Grid)`
  height: 100%;
  justify-content: center;
  align-content: center;
`;

export const TabContainer = styled(Box)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 2}px ${
      props.theme.palette.spacing[1] * 0
    }px`};
`;

export const StickyTab = styled(Tabs)`
  position: sticky;
  top: ${(props) => `${props.theme.palette.spacing[1] * 9.5}px`};
  z-index: 1;
`;

export const Container = styled(Box)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 3
    }px`};
`;

export const FormGrid = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[0] * 9.5
    }px`};
`;

export const GridTopZero = styled(Grid)`
  padding-top: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
`;

export const GridTop = styled(Grid)`
  padding-top: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const GridTop2 = styled(Grid)`
  padding: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const FieldText = styled(TextField)`
  width: 100%;
`;

export const GridHeading = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 2}px ${
      props.theme.palette.spacing[1] * 3
    }px`};
  align-items: center;
`;

export const EndGrid = styled(Grid)`
  display: flex;
  justify-content: end;
`;

export const HelperText = styled(FormHelperText)`
  margin-top: ${(props) => `-${props.theme.palette.spacing[0] * 3}px`};
`;
export const MultiFormGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const IconButtonMB = styled(IconButton)`
  margin-bottom: 10px;
`;

export const WrapperBox = styled(BoxWrapper)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 3
    }px`};
`;

export const HeadingFormGrid = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 3
    }px`};
`;

export const ItemTextList = styled(ListItemText)`
  font-size: 15px;
  font-weight: 400;
  color: #7b809a;
`;

export const DialogHeading = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 2
    }px`};
`;

export const DialogDataGrid = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 2
    }px ${props.theme.palette.spacing[1] * 2}px ${
      props.theme.palette.spacing[1] * 2
    }px`};
`;

export const MaxDateErrorTypography = styled(Typography)`
  color: ${(props) => `${props.theme.palette.error.main}`};
`;

export const NoWrap = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const GridCenterWrapper = styled(GridWrapper)`
  align-items: center;
  padding-top: 8px;
`;

export const ButtonListItem = styled(ListItemButton)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 1.5}px ${
      props.theme.palette.spacing[1] * 0
    }px ${props.theme.palette.spacing[1] * 1}px ${
      props.theme.palette.spacing[1] * 0
    }px`};
`;

export const IconDragIndicator = styled(DragIndicatorIcon)`
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const scrollbarStyles = `
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.scrollbarColors.gainsboro};
    border-radius: 10px;
  }
`;