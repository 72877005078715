import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm, useFieldArray } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { GridForm, GridButtonWrapper } from 'components/Ui/styled';
import List from '@mui/material/List';
import { useSelector, useDispatch } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  showBackdropLoader,
  fieldValidation,
  getDragAndDroppedColumns,
} from 'utils/commonUtils';
import { requiredLimit, characterLimit } from 'constants';
import { resetTrackingForm } from 'layouts/studentTracking/store/trackingSlice/trackingSlice';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MultiEditFormTextField from 'components/Common/Forms/MultiEditFormTextField';
import { PaperPropsCheck, requiredField } from 'constants';
import {
  BoxWrapper,
  PButton,
  ButtonWrapper,
  ButtonListItem,
  IconDragIndicator,
} from 'components/Ui/styled';
import {
  postColumn,
  postRenameTracker,
  putReorderTrackerColumns,
  postColumnRename,
} from 'layouts/studentTracking/services/studentTrackerApi';
import { getSnackBar } from 'utils/commonUtils';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import { trackerSuccess } from 'constants';
import { getNewColumns } from 'utils/commonUtils';
import { getRenamedColumns } from 'utils/commonUtils';

const schema = yup.object().shape({
  name: fieldValidation(characterLimit),
  fields: yup.array().of(
    yup.object().shape({
      value: yup.string().max(100, requiredLimit).required(requiredField),
    })
  ),
});

const TrackerEditDialog = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    isFormLoading,
    trackerId,
    fetchTrackers,
    setTrackerId,
  } = props;

  const { handleErrorResponse, NotificationPopup } = useNotification();

  const [loadingButton, setLoadingButton] = useState(false);
  const [editableFieldIndex, setEditableFieldIndex] = useState(null);
  const [isDragAndDropped, setIsDragAndDropped] = useState(false);
  const [isColumnAdded, setIsColumnAdded] = useState(false);

  const trackingForm = useSelector((state) => state?.trackingForm);
  const { name, arrayOfColumns, isNewTracking } = trackingForm || {};

  const editColumns = arrayOfColumns?.map((col) => ({ value: col }));

  const defaultValue = {
    name: name,
    fields: isNewTracking ? [{ value: '' }] : editColumns,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, formState } = useFunction;
  const { errors } = formState;

  const { fields, append, move } = useFieldArray({
    control,
    name: 'fields',
  });

  useEffect(() => {
    reset({
      ...defaultValue,
      fields: defaultValue.fields?.length
        ? defaultValue.fields
        : [{ value: '' }],
    });
  }, [name, JSON.stringify(defaultValue.fields)]);

  const handleClose = () => {
    reset(defaultValue);
    dispatch(resetTrackingForm());
    setEditableFieldIndex(null);
    setTrackerId(null);
    setIsDragAndDropped(false);
    setIsColumnAdded(false);
    setOpen(false);
  };

  const handleDragDrop = (result) => {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
    setIsDragAndDropped(true);
  };

  const handleAddColumn = () => {
    append({ value: '' });
    setIsColumnAdded(true);
  };

  const isMaxColumns = fields.length < 10 ? false : true;

  const handleEditClick = (index) => {
    setEditableFieldIndex(index);
  };

  const onSubmit = async (data) => {
    const { name, fields } = data;
    const columns = fields?.map((item) => item.value.trim());

    const initialArray = defaultValue.fields?.map((item) => item.value.trim());

    const isReordered = getDragAndDroppedColumns(initialArray, columns);
    const columNames = getNewColumns(initialArray, columns);
    const renamedColumns = getRenamedColumns(initialArray, columns);

    const snackBar = getSnackBar(trackerSuccess);

    try {
      setLoadingButton(true);
      if (isColumnAdded) {
        for (let i = 0; i < columNames.length; i++) {
          const columNamePayload = {
            columName: columNames[i],
          };
          await postColumn(columNamePayload, trackerId);
        }
      }
      if (renamedColumns.length > 0) {
        for (let i = 0; i < renamedColumns.length; i++) {
          const renameColumnPayload = {
            columnName: renamedColumns[i].original,
            newColumnName: renamedColumns[i].edited,
          };
          await postColumnRename(renameColumnPayload, trackerId);
        }
      }
      if (name !== defaultValue.name) {
        const renameTrackerNamePayload = {
          name,
        };
        await postRenameTracker(renameTrackerNamePayload, trackerId);
      }
      if (isReordered && isDragAndDropped) {
        const reorderTrackerColumnsPayload = {
          columnOrder: columns,
        };
        await putReorderTrackerColumns(reorderTrackerColumnsPayload, trackerId);
      }
      dispatch(displaySnackbar(snackBar));
      setLoadingButton(false);
      setOpen(false);
      fetchTrackers();
    } catch (error) {
      setLoadingButton(false);
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  return (
    <>
      {showBackdropLoader(isFormLoading)}
      <Dialog open={open} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Edit Tracker', handleClose, 'editTracker')}
        <BoxWrapper>
          <GridForm container spacing={2}>
            <Grid item xs={12}>
              <FormInputText
                name="name"
                type="text"
                control={control}
                label="Name"
                helperText={errors?.name?.message}
                errors={!!errors.name}
              />
              <ButtonWrapper container>
                <PButton
                  variant="text"
                  onClick={handleAddColumn}
                  disabled={isMaxColumns}
                >
                  Add Column +
                </PButton>
              </ButtonWrapper>
              <DragDropContext onDragEnd={handleDragDrop}>
                <Droppable droppableId="reorderColumns">
                  {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ButtonListItem>
                                <ListItemIcon>
                                  <IconDragIndicator fontSize="medium" />
                                </ListItemIcon>
                                <MultiEditFormTextField
                                  label="Column"
                                  fields={fields}
                                  field={item}
                                  index={index}
                                  control={control}
                                  errors={errors}
                                  isEditable={editableFieldIndex === index}
                                  onEditClick={() => handleEditClick(index)}
                                />
                              </ButtonListItem>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </GridForm>
        </BoxWrapper>
        <GridButtonWrapper container>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridButtonWrapper>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default TrackerEditDialog;
